import 'swagger-ui-react/swagger-ui.css';

import './App.css';

import React from 'react';
import Helmet from 'react-helmet';
import SwaggerUI from 'swagger-ui-react';

import CapitalRoadFavIcon from './CapitalRoad/favicon.png';
import CapitalRoadLogo from './CapitalRoad/logo.png';
import CapitalRoadOpenAPI from './CapitalRoad/openapi.json';
import Navag8FavIcon from './Navag8/favicon.png';
import Navag8Logo from './Navag8/logo.jpg';
import Navag8OpenAPI from './Navag8/openapi.json';

const App = () => (
  <div id="app">
    <Helmet>
      <title>{`${window.location.href.indexOf('navag8') > -1 ? 'Navag8' : 'CapitalRoad'} - Developer Documentation`}</title>
      <link
        rel="icon"
        href={window.location.href.indexOf('navag8') > -1 ? Navag8FavIcon : CapitalRoadFavIcon}
      />
    </Helmet>
    <div>
      <div>
        <div className="logo-container">
          <img
            src={window.location.href.indexOf('navag8') > -1 ? Navag8Logo : CapitalRoadLogo}
            alt={window.location.href.indexOf('navag8') > -1 ? 'Navag8' : 'CapitalRoad'}
            style={{ height: '40px' }}
          />
        </div>
      </div>
    </div>
    <SwaggerUI spec={window.location.href.indexOf('navag8') > -1 ? Navag8OpenAPI : CapitalRoadOpenAPI} />
  </div>
);

export default App;
